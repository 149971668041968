export default function Color(r, g, b, a) {
  this.r = r
  this.g = g
  this.b = b
  this.a = a
}

Color.prototype = {
  render: function() {
    return 'rgba(' + this.r + ',' + this.g + ',' + this.b + ',' + this.a + ')'
  }
}
