let canvas,
  context,
  renderFn,
  requestFrame =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function(callback) {
      window.setTimeout(callback, 1000 / 60)
    }

const Drawing = {
  init: function(el) {
    canvas = el instanceof HTMLElement ? el : document.querySelector(el)
    context = canvas.getContext('2d')
    this.adjustCanvas()

    window.addEventListener('resize', function() {
      Drawing.adjustCanvas()
    })
  },

  loop: function(fn) {
    renderFn = !renderFn ? fn : renderFn
    this.clearFrame()
    renderFn()
    requestFrame.call(window, this.loop.bind(this))
  },

  adjustCanvas: function() {
    canvas.width = document.documentElement.clientWidth
    canvas.height = document.documentElement.clientHeight
  },

  clearFrame: function() {
    context.clearRect(0, 0, canvas.width, canvas.height)
  },

  getArea: function() {
    return { w: canvas.width, h: canvas.height }
  },

  drawCircle: function(p, c) {
    context.fillStyle = c.render()
    context.beginPath()
    context.arc(p.x, p.y, p.z, 0, 2 * Math.PI, true)
    context.closePath()
    context.fill()
  }
}

export default Drawing
