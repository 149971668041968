import React from 'react'
import Animation from '../Animation'
import './index.css'

export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.canvasElement = React.createRef()
  }

  componentDidMount() {
    Animation.init({
      el: this.canvasElement.current,
      // usage:
      /**
       * usage:
       * 1. 使用 | 符号作为分隔符
       * 2. 若需要在最后一帧清屏，需使用 a| 语法，否则使用 a 结尾将保持 a 为最后一帧
       */
      text:
        '世界上|本没有|什么|完美的|爱情，|无非是|经历|风雨后,|还|紧握着|对方的|手.|朱乐怡,|我能|遇见你|是我|此生的|幸运|5201314|LBW❤ZLY',
      frameDelay: 2999
    })
  }

  render() {
    return (
      <>
        <canvas ref={this.canvasElement} />
      </>
    )
  }
}
