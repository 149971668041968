import Drawing from '../Drawing'
import Point from '../Point'
import Color from '../Color'

export default function Dot(x, y) {
  this.p = new Point({
    x: x,
    y: y,
    z: 5,
    a: 1,
    h: 0
  })

  this.e = 0.07
  this.s = true

  this.c = new Color(255, 255, 255, this.p.a)

  this.t = this.clone()
  this.q = []
}

Dot.prototype = {
  clone: function() {
    return new Point({
      x: this.x,
      y: this.y,
      z: this.z,
      a: this.a,
      h: this.h
    })
  },

  _draw: function() {
    this.c.a = this.p.a
    Drawing.drawCircle(this.p, this.c)
  },

  _moveTowards: function(n) {
    var details = this.distanceTo(n, true),
      dx = details[0],
      dy = details[1],
      d = details[2],
      e = this.e * d

    if (this.p.h === -1) {
      this.p.x = n.x
      this.p.y = n.y
      return true
    }

    if (d > 1) {
      this.p.x -= (dx / d) * e
      this.p.y -= (dy / d) * e
    } else {
      if (this.p.h > 0) {
        this.p.h--
      } else {
        return true
      }
    }

    return false
  },

  _update: function() {
    var p, d

    if (this._moveTowards(this.t)) {
      p = this.q.shift()

      if (p) {
        this.t.x = p.x || this.p.x
        this.t.y = p.y || this.p.y
        this.t.z = p.z || this.p.z
        this.t.a = p.a || this.p.a
        this.p.h = p.h || 0
      } else {
        if (this.s) {
          this.p.x -= Math.sin(Math.random() * 3.142)
          this.p.y -= Math.sin(Math.random() * 3.142)
        } else {
          this.move(
            new Point({
              x: this.p.x + Math.random() * 50 - 25,
              y: this.p.y + Math.random() * 50 - 25
            })
          )
        }
      }
    }

    d = this.p.a - this.t.a
    this.p.a = Math.max(0.1, this.p.a - d * 0.05)
    d = this.p.z - this.t.z
    this.p.z = Math.max(1, this.p.z - d * 0.05)
  },

  distanceTo: function(n, details) {
    var dx = this.p.x - n.x,
      dy = this.p.y - n.y,
      d = Math.sqrt(dx * dx + dy * dy)

    return details ? [dx, dy, d] : d
  },

  move: function(p, avoidStatic) {
    if (!avoidStatic || (avoidStatic && this.distanceTo(p) > 1)) {
      this.q.push(p)
    }
  },

  render: function() {
    this._update()
    this._draw()
  }
}
