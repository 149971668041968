import Drawing from './Drawing'
import ShapeBuilder from './ShapeBuilder'
import UI from './UI'
import Shape from './Shape'

export default {
  init({ el, text, frameDelay }) {
    // 设定画板 canvas 宽高，并监听 resize 事件
    Drawing.init(el)
    // 创建一个 canvas 并为该新创建的 canvas 设置尺寸及样式
    ShapeBuilder.init()
    // resize 事件监听，并在 resize 事件触发的 500ms 后执行绘制
    UI.init()

    UI.simulate(text, frameDelay)

    Drawing.loop(function() {
      Shape.render()
    })
  }
}
