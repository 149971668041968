import Drawing from '../Drawing'
import Point from '../Point'
import Dot from '../Dot'

var dots = [],
  width = 0,
  height = 0,
  cx = 0,
  cy = 0

function compensate() {
  var a = Drawing.getArea()

  cx = a.w / 2 - width / 2
  cy = a.h / 2 - height / 2
}

export default {
  shuffleIdle: function() {
    var a = Drawing.getArea()

    for (var d = 0; d < dots.length; d++) {
      if (!dots[d].s) {
        dots[d].move({
          x: Math.random() * a.w,
          y: Math.random() * a.h
        })
      }
    }
  },

  switchShape: function(n, fast) {
    var size,
      a = Drawing.getArea(),
      d = 0,
      i = 0

    width = n.w
    height = n.h

    compensate()

    if (n.dots.length > dots.length) {
      size = n.dots.length - dots.length
      for (d = 1; d <= size; d++) {
        dots.push(new Dot(a.w / 2, a.h / 2))
      }
    }

    d = 0

    while (n.dots.length > 0) {
      i = Math.floor(Math.random() * n.dots.length)
      dots[d].e = fast ? 0.25 : dots[d].s ? 0.14 : 0.11

      if (dots[d].s) {
        dots[d].move(
          new Point({
            z: Math.random() * 20 + 10,
            a: Math.random(),
            h: 18
          })
        )
      } else {
        dots[d].move(
          new Point({
            z: Math.random() * 5 + 5,
            h: fast ? 18 : 30
          })
        )
      }

      dots[d].s = true
      dots[d].move(
        new Point({
          x: n.dots[i].x + cx,
          y: n.dots[i].y + cy,
          a: 1,
          z: 5,
          h: 0
        })
      )

      n.dots = n.dots.slice(0, i).concat(n.dots.slice(i + 1))
      d++
    }

    for (i = d; i < dots.length; i++) {
      if (dots[i].s) {
        dots[i].move(
          new Point({
            z: Math.random() * 20 + 10,
            a: Math.random(),
            h: 20
          })
        )

        dots[i].s = false
        dots[i].e = 0.04
        dots[i].move(
          new Point({
            x: Math.random() * a.w,
            y: Math.random() * a.h,
            a: 0.3, //.4
            z: Math.random() * 4,
            h: 0
          })
        )
      }
    }
  },

  render: function() {
    for (var d = 0; d < dots.length; d++) {
      dots[d].render()
    }
  }
}
